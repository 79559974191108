


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Moment } from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import Avatar from '@/_components/avatar/avatar.vue';
import { TContact } from '@/_types/contact.type';
import FileHelper from '@/_helpers/file.helper';
import IconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';
import IconZoomIn from '@/_modules/icons/components/icon-zoom-in.vue';
import IconUnpin from '@/_modules/icons/components/icon-unpin.vue';
import IconBroadcast from '@/_modules/icons/components/icon-broadcast.vue';
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import IconSpeaker from '@/_modules/icons/components/icon-speaker.vue';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import PromoBroadcastMenu from '@/_modules/promo/components/promo-broadcast-menu/promo-broadcast-menu.vue';
import { TEvent } from '@/_types/event.type';
import { TUser } from '@/_types/user.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import ProgramChat from '@/_modules/promo-program/components/program-chat/program-chat.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import PromoContact from '@/views/components/promoPage/contacts/contact.vue';
import IconStar from '@/_modules/icons/components/icon-star.vue';
import IconShare from '@/_modules/icons/components/icon-share.vue';
import IconRatingStar from '@/_modules/icons/components/icon-rating-star.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
// @ts-ignore
import Statistics from '@/services/statistics.js';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import promoProgramApi, {TConferenceProgramRating} from '@/_modules/promo-program/api/promo-program.api';
import {TSessionRatings} from '@/_modules/statistics/types/event-stat.type';
import EventHelper from '@/_helpers/event.helper';
import {TimeStatus} from '@/_types/time-status.enum';
import { QuestionTypes, TQuestionnaire, TQuestionnaireQuestionAnswerOption } from '@/_types/questionnaire.type';
import questionnairesApi from '@/_api/questionnaires.api';
import _cloneDeep from 'lodash.clonedeep';
import eventDiscoveryService, {TEventDiscoveryServiceConfig} from '@/_services/event-discovery.service';
import {Action, Getter} from 'vuex-class';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';

type TStar = {
  starNumber: number;
}

const beforeRouteEnter: {to: Route; from: Route; next?: NavigationGuardNext} = { to: null, from: null, next: null };
const STORED_PROGRAM_RATINGS_KEY_NAME = 'storedProgramRatings';

@Component({
  components: {
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuItemLink,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    Avatar,
    IconZoomOut,
    IconZoomIn,
    IconUnpin,
    IconBroadcast,
    IconSpeaker,
    iconCloseCircleOutline,
    ProgramMediaBlock,
    PromoBroadcastMenu,
    ProgramChat,
    PromoContact,
    IconStar,
    IconShare,
    IconRatingStar,
    SimplePopup,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      user: '_userStore/user',
      contact: 'promoPageStore/contact',
      getChatGroupState: 'chatStore/getChatGroupStateByGroupId',
      getProgramById: 'promoProgramStore/getProgramById',
    }),
  }
})
export default class PromoProgramDetails extends Vue {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string}) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Getter('ewSharerStore/isSharerVisible') isSharerVisible: boolean;

  public readonly BroadcastType: typeof BroadcastType = BroadcastType;
  public questionnaires: TQuestionnaire[] = [];

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  public tempProgramId: number = null;
  public isServiceConnected: boolean = false;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly contact: TContact;
  public readonly getChatGroupState: (groupId: string) => TChatGroupState;
  public readonly getProgramById: (programId: number) => TConferenceProgram;

  public fullscreenMode: boolean = false;
  public isPromoBroadcastMenuVisible: boolean = false;
  public isUnpinActionDisabled: boolean = true;
  public isRatingPopupVisible: boolean = false;
  public currentRating: number = 0;
  public ratingLoaded: boolean = false;
  public stars: TStar[] = [
    {
      starNumber: 1,
    },
    {
      starNumber: 2,
    },
    {
      starNumber: 3,
    },
    {
      starNumber: 4,
    },
    {
      starNumber: 5,
    },
  ];

  private destroyed$: Subject<void> = new Subject<void>();

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  public mounted(): void {
    this.subscribeToPageEvents();
    this.countProgramSessionView();
    this.subscribeToServiceEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.unSubscribeFromProgramSessionTopic();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programState(): TConferenceProgram {
    return (this.program && this.program.id && this.getProgramById(this.program.id)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return (this.program && this.program.id) || null;
  }

  public get contactId(): number {
    return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id, 10)) || null;
  }

  @Watch('contactId', {immediate: true})
  private onContactIdChange(newVal: number): void {
    if (newVal) {
      this.openContactCardFromUrl();
    }
  }

  public openContactCardFromUrl(): void {
    if (this.contactId) {
      this.openContactCard({
        contactId: this.contactId,
      });
    }
  }

  public get selectedSpeaker(): TContact {
    const contactId = this.contactId;
    if (!this.contactId || !this.program || !this.program.speakers || !this.program.speakers.length) {
      return null;
    }
    return this.program.speakers.find(speaker => speaker.id === contactId) || null;
  }

  public get uniqueProgramSpeakers(): TContact[] {

    // This was made for AW-2021
    if (!this.program || !this.program.speakers || !this.program.speakers.length) {
      return [];
    }
    const speakers = this.program.speakers;
    const speakersById: { [key: number]: TContact } = {};
    speakers.forEach((speaker) => {
      speakersById[speaker.id] = speaker;
    });
    return Object.values(speakersById);
  }

  public get liveChatGroupId(): string {
    return this.program.show_live_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.LIVE) : null;
  }

  public get liveChatGroupState(): TChatGroupState {
    const liveChatGroupId = this.liveChatGroupId;
    return (liveChatGroupId && this.getChatGroupState(liveChatGroupId)) || null;
  }

  public get speakerChatGroupId(): string {
    return this.program.show_speaker_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.SPEAKER) : null;
  }

  public get speakerChatGroupState(): TChatGroupState {
    const speakerChatGroupId = this.speakerChatGroupId;
    return (speakerChatGroupId && this.getChatGroupState(speakerChatGroupId)) || null;
  }

  public get isAnyChatOn(): boolean {
    return this.program && (this.program.show_speaker_chat || this.program.show_live_chat);
  }

  public get isPollOn(): boolean {
    return this.program && !!this.mainPoll;
  }

  public get mainPoll(): TQuestionnaire {
    if (!this.questionnaires) {
      return null;
    }

    let result = Object.assign({}, this.questionnaires[0]);
    result.questions = result.questions ? result.questions.filter((question): boolean => question.is_enabled) : [];

    result = this.sortRatingOptions(result);

    return result;
  }

  private sortRatingOptions(poll: TQuestionnaire): TQuestionnaire {
    // For AW-2365 sort all RATING options by parseInt(.text, 10) ascending
    const result = _cloneDeep(poll);
    result.questions
      .filter(q => q.question_type === QuestionTypes.RATING)
      .forEach(q => {
        const sortedAnswerOptions: TQuestionnaireQuestionAnswerOption[] = [];
        const optionsOrdering: string[] = '1,2,3,4,5,6,7,8,9,10'.split(',');
        for (let i = 1; i <= optionsOrdering.length; i++) {
          const option = q.answer_options.find((a) => a.text === optionsOrdering[i - 1]);
          if (option) {
            sortedAnswerOptions.push(option);
          }
        }
        q.answer_options = _cloneDeep(sortedAnswerOptions);
      });
    return result;
  }

  private async getProgramQuestionnaires(): Promise<void> {
    if (!this.program && !this.program.id) {
      return;
    }

    try {
      const result: TQuestionnaire[] = await questionnairesApi.getProgramQuestionnaires({
        eventId: this.eventId,
        programId: this.program.id
      });
      if (result) {
        this.questionnaires = result;
      }
    } catch {
      this.questionnaires = [];
    }
  }

  // public get isAnyChatActive(): boolean {
  //   return !!this.liveChatGroupState || !!this.speakerChatGroupState;
  // }

  public get dateStartMoment(): Moment {
    return (this.program && this.program.date_start) ? this.$moment(this.program.date_start) : null;
  }

  public get dateEndMoment(): Moment {
    return (this.program && this.program.date_end) ? this.$moment(this.program.date_end) : null;
  }

  public get timeText(): string {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    let result = '';
    if (dateStartMoment) {
      result += dateStartMoment.format(DateTimeFormat.HOURS_MINUTES);
    }
    if (dateEndMoment) {
      if (result) {
        result += ' - ';
      }
      result += dateEndMoment.format(DateTimeFormat.HOURS_MINUTES);
    }
    if (dateStartMoment) {
      if (result) {
        result += ', ';
      }
      result += dateEndMoment.format(DateTimeFormat.MONTH_DATE);
    }
    return result;
  }

  public get canStartBroadcast(): boolean {

    if (!this.user || !this.event || !this.contact || !this.program || !this.event.is_enabled) {
      return false;
    }

    if (this.event.creator_user_id === this.user.id) {
      return true;
    }

    if (this.program.speakers && this.program.speakers.length) {
      if (this.program.speakers.find(speaker => speaker.id === this.contact.id)) {
        return true;
      }
    }

    return false;
  }

  public get isEventOwner(): boolean {
    if (!this.user || !this.event) {
      return false;
    }

    return this.event.creator_user_id === this.user.id;
  }

  public get shareUrl(): string {
    return location.origin + this.$router.resolve({
      name: 'promo-program-date-program',
      params: {
        eventId: '' + this.eventId,
        date: this.date,
        programId: '' + this.programId,
      }
    }).href;
  }

  public onContactNavSelected(): void {
    this.$emit('sizeChange');
  }

  public onShareClick(event: PointerEvent): void {
    if (this.isSharerVisible) {
      this.closeSharer();
      return;
    }
    this.openSharer({
      eventTarget: event.target as Element,
      url: this.shareUrl
    });
  }

  public toggleFavorite(): void {
    const programState = this.programState;
    if (!programState) {
      return;
    }
    this.$store.dispatch('promoProgramStore/toggleFavorite', {
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: !programState.is_favorite,
    });
  }

  public onActionBroadcastClick(event: MouseEvent): void {
    event.stopPropagation();

    this.isPromoBroadcastMenuVisible = !this.isPromoBroadcastMenuVisible;

    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
    }
  }

  public setFullscreenMode(isEnabled: boolean): void {
    this.fullscreenMode = isEnabled;
  }

  public onActionCloseClick(): void {
    const dateStartMoment = this.dateStartMoment;
    this.$router.push({
      name: 'promo-program-date',
      params: Object.assign({
        date: dateStartMoment ? dateStartMoment.format(DateTimeFormat.DATE_TINY) : undefined,
      }, this.$route.params, { programId: undefined }),
    });
  }

  public fileExt(fileName: string): string {
    return FileHelper.getFileExtension(fileName);
  }

  public onUnpinClick(): void {
    if (this.isUnpinActionDisabled || !this.program || !this.program.activeMediaItem) {
      return;
    }

    const activeMediaItem = this.program.activeMediaItem;
    if (!activeMediaItem) {
      return;
    }
    const node = document.getElementById('program-media-item-' + activeMediaItem.id);

    this.$store.dispatch('promoProgramStore/unpinMediaItem', {
      programId: this.program.id,
      id: activeMediaItem.id,
    });

    if (node && (activeMediaItem.type === 'video' || activeMediaItem.type === 'embed')) {
      this.$nextTick(() => {
        const container = document.getElementById('media-item-container-' + activeMediaItem.id);
        if (container) {
          container.append(node);
        }
      });
    }
  }

  @Watch('program', { immediate: true, deep: true })
  private onProgramChange(newValue: TConferenceProgram, oldValue: TConferenceProgram): void {
    this.isUnpinActionDisabled = !this.program || !this.program.activeMediaItem || this.program.activeMediaItem.type === 'image';
    if ((newValue && !oldValue)) {
      this.getProgramQuestionnaires();
    } else if((newValue.id !== oldValue.id) || (newValue.conference_id !== oldValue.conference_id)) {
      this.getProgramQuestionnaires();
    }
  }

  public subscribeToProgramSessionTopic(id: number): void {
    if(this.tempProgramId && id === this.tempProgramId) { return; }
    if (eventDiscoveryService.isConnected()) {
      eventDiscoveryService.subscribeToProgramSessionTopic(id);
      this.tempProgramId = id;
    }

  }

  public unSubscribeFromProgramSessionTopic(): void {
    if(!this.tempProgramId) { return; }
    eventDiscoveryService.unSubscribeFromProgramSessionTopic(this.tempProgramId);
  }

  @Watch('programId')
  private onProgramIdChange(): void {
    this.countProgramSessionView();
    this.unSubscribeFromProgramSessionTopic();
    this.subscribeToProgramSessionTopic(this.programId);
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected && this.programId) {
      this.subscribeToProgramSessionTopic(this.programId);
    }
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  // @Watch('programState', { immediate: true, deep: true })
  // private onProgramStateChange(): void {
  //   console.log('');
  //   console.log('onProgramStateChange', this.programState);
  //   console.log('');
  // }

  private subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);
  }

  private onDocumentClick(): void {
    this.isPromoBroadcastMenuVisible = false;
  }

  private countProgramSessionView(): void {
    if (this.isEventOwner) {
      return;
    }
    Statistics.programSessionView({
      eventId: this.eventId,
      programSessionId: this.programId,
    }, beforeRouteEnter);
  }

  private closeRatingPopup(): void {
    this.isRatingPopupVisible = false;
  }

  private showRatingPopup(): void {
    this.getProgramRating();
    this.isRatingPopupVisible = true;
  }

  private getProgramRating(): void {
    let result: TConferenceProgramRating = { rating: 0 };
    let storedProgramRatings: TSessionRatings = {};
    try {
      storedProgramRatings = JSON.parse(window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME));
      if (Object.prototype.hasOwnProperty.call(storedProgramRatings, this.programId)) {
        result = { rating: storedProgramRatings[this.programId] };
      }
    } catch (error) {
      result = { rating: 0 };
    }

    this.currentRating = result.rating;
    this.ratingLoaded = true;
  }

  private setLocalProgramRating(rating: number): void {
    if (rating < 1 || rating > 5) {
      return;
    }
    this.currentRating = rating;
    this.saveCurrentRating();

    this.setStarHovered({starNumber: this.currentRating});
    this.$nextTick(() => {
      this.setStarHovered({starNumber: this.currentRating});
    });
  }

  private saveCurrentRating(): void {
    let storedProgramRatings: TSessionRatings = {};
    if (window.localStorage && window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME)) {
      storedProgramRatings = JSON.parse(window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME));
    }
    storedProgramRatings[this.programId] = this.currentRating;
    window.localStorage.setItem(STORED_PROGRAM_RATINGS_KEY_NAME, JSON.stringify(storedProgramRatings));
  }

  private async sendProgramRating(): Promise<void> {
    if (this.currentRating < 1 || this.currentRating > 5) {
      return;
    }
    await promoProgramApi.patchConferenceProgramRating({
      eventId: this.eventId,
      conferenceId: this.program.conference_id,
      programId: this.program.id,
      rating: this.currentRating
    });
    this.closeRatingPopup();
  }

  private isPartialStarNeeded(starNumber: number): boolean {
    return Math.floor(this.currentRating + 1) === starNumber;
  }

  private getPartialStarPercentageWidth(): string {
    const width = ((this.currentRating - Math.floor(this.currentRating)) * 100).toFixed(0);
    const cssUnit = width === '0' ? '' : '%';
    return width + cssUnit;
  }

  private getStarClasses(star: TStar): { [key: string]: boolean } {
    const isFullStar: boolean = Math.floor(this.currentRating) >= star.starNumber;
    const isPartialStar: boolean = this.isPartialStarNeeded(star.starNumber);
    return {
      'full-star': isFullStar,
      'partial-star': isPartialStar
    };
  }

  private setStarHovered(star: TStar): void {
    if (this.$refs.programRatingStars) {
      const refStars: Element[] = (this.$refs.programRatingStars as Element[]);
      for (let i = 0; i < refStars.length; i++) {
        if (i + 1 <= star.starNumber) {
          (refStars[i] as HTMLElement).classList.add('hovered-star');
        } else {
          (refStars[i] as HTMLElement).classList.remove('hovered-star');
        }
      }
    }
  }

  private unsetStarHovered(): void {
    this.setStarHovered({starNumber: -1});
  }

  public generateLinksWithImagePreviews(text: string): string {
    return ChatHelper.createLinks(text);
  }

}
