


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Location } from 'vue-router';
import { Moment } from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TEvent } from '@/_types/event.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeftLink
  from '@/_modules/controls/components/horizontal-menu-arrow-left-link/horizontal-menu-arrow-left-link.vue';
import HorizontalMenuArrowRightLink
  from '@/_modules/controls/components/horizontal-menu-arrow-right-link/horizontal-menu-arrow-right-link.vue';
import PromoProgramListItem from '@/_modules/promo-program/components/promo-program-list-item/promo-program-list-item.vue';
import PromoProgramDetails from '@/_modules/promo-program/components/promo-program-details/promo-program-details.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import IconSearch from '@/_modules/icons/components/icon-search.vue';

// const SEARCH_DEBOUNCE_TIME = 1000;

type TConferenceRoomProgram = {
  id: number;
  title: string;
  programs: TConferenceProgram[];
};

type TProgramByDate = {
  date: Moment;
  dateText: string;
  dateParam: string;
  rooms: TConferenceRoomProgram[];
};

@Component({
  components: {
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    HorizontalMenuItemLink,
    HorizontalMenuArrowLeftLink,
    HorizontalMenuArrowRightLink,
    PromoProgramListItem,
    PromoProgramDetails,
    IconSearch,
  },
  computed: {
    ...mapGetters({
      isProgramLoading: 'promoProgramStore/isLoading',
      conferenceRooms: 'promoProgramStore/conferenceRooms',
      lastError: 'promoProgramStore/lastError',
      event: '_eventStore/event',
    }),
  }
})
export default class PromoProgram extends Vue {

  public readonly isProgramLoading: boolean;
  public readonly conferenceRooms: TConferenceRoom[];
  public readonly event: TEvent;
  public readonly lastError: Error;

  public currentDateMoment: Moment;
  public programByDates: TProgramByDate[] = [];
  public selectedDate: Moment = null;
  public selectedDateParam: string = null;

  public visibleProgramByDates: TProgramByDate[] = [];
  public selectedProgramByDate: TProgramByDate = null;

  public maxVisibleConferenceRoomPrograms: number = 3;
  public visibleConferenceRoomPrograms: TConferenceRoomProgram[] = [];
  public firstVisibleConferenceRoomProgramIndex: number = 0;

  public selectedConferenceRoomProgram: TConferenceRoomProgram = null;
  public selectedProgram: TConferenceProgram = null;

  public searchString: string = '';

  private destroyed$: Subject<void> = new Subject<void>();
  private applyNavigation$: Subject<void> = new Subject<void>();
  // private updateSearch$: Subject<void> = new Subject<void>();
  private sortProgramsIntervalId: number;

  constructor() {
    super();

    this.currentDateMoment = this.$moment();

    this.applyNavigation$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(100),
    ).subscribe(() => {
      this.applyNavigation();
      this.$nextTick(() => {
        this.updateMaxVisibleConferenceRoomPrograms();
      });
    });

    // this.updateSearch$.pipe(
    //   takeUntil(this.destroyed$),
    //   debounceTime(SEARCH_DEBOUNCE_TIME),
    // ).subscribe(() => {
    //   this.setQuerySearch();
    // });
  }

  public mounted(): void {
    this.sortProgramsIntervalId = window.setInterval(() => {
      this.currentDateMoment = this.$moment();
      // this.sortProgramByDates();
    }, 10000);

    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.applyNavigation$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.sortProgramsIntervalId) {
      window.clearInterval(this.sortProgramsIntervalId);
    }
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return this.$route.params.programId ? parseInt(this.$route.params.programId, 10) : null;
  }

  public get queryType(): 'all' | 'my' {
    return this.$route.query.type === 'my' ? 'my' : 'all';
  }

  public get querySearch(): string {
    return ('' + this.$route.query.search) || '';
  }

  public get selectedProgramByDateIndex(): number {
    return this.programByDates.indexOf(this.selectedProgramByDate);
  }

  public get isDatesMenuArrowsVisible(): boolean {
    return this.programByDates.length > 3;
  }

  public get isDatesMenuArrowLeftDisabled(): boolean {
    return this.selectedProgramByDateIndex < 1;
  }

  public get isDatesMenuArrowRightDisabled(): boolean {
    return this.selectedProgramByDateIndex >= this.programByDates.length - 1;
  }

  public get datesMenuArrowLeftTo(): Location {
    const currentIndex = this.selectedProgramByDateIndex;
    if (!this.programByDates || !this.programByDates.length || currentIndex < 0) {
      return {
        name: 'promo-program',
        query: this.$route.query
      };
    }
    const nextIndex = currentIndex - 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length ) ? {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      },
      query: this.$route.query
    } : {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      },
      query: this.$route.query
    };
  }

  public get datesMenuArrowRightTo(): Location {
    const currentIndex = this.selectedProgramByDateIndex;
    if (!this.programByDates || !this.programByDates.length || currentIndex < 0) {
      return {
        name: 'promo-program',
        query: this.$route.query
      };
    }
    const nextIndex = currentIndex + 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length) ? {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      },
      query: this.$route.query
    } : {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      },
      query: this.$route.query
    };
  }

  public get isConferenceRoomsMenuArrowsVisible(): boolean {
    if (!this.selectedProgramByDate) {
      return false;
    }
    return this.selectedProgramByDate.rooms.length > this.maxVisibleConferenceRoomPrograms;
  }

  public get isConferenceRoomsMenuArrowLeftDisabled(): boolean {
    if (!this.selectedProgramByDate) {
      return true;
    }
    return this.firstVisibleConferenceRoomProgramIndex < 1;
  }

  public get isConferenceRoomsMenuArrowRightDisabled(): boolean {
    if (!this.selectedProgramByDate) {
      return true;
    }
    return this.firstVisibleConferenceRoomProgramIndex >= this.selectedProgramByDate.rooms.length - this.maxVisibleConferenceRoomPrograms;
  }

  public get isEmptyProgram(): boolean {
    if (
      !this.conferenceRooms
      || !this.conferenceRooms.length
      || !this.programByDates
      || !this.programByDates.length
    ) {
      return true;
    }
    for (let i = 0; i < this.conferenceRooms.length; i++) {
      if (this.conferenceRooms[i].programs && this.conferenceRooms[i].programs.length > 0) {
        return false;
      }
    }
    return true;
  }

  public onConferenceRoomMenuItemClick(conferenceRoomProgram: TConferenceRoomProgram): void {
    if (this.selectedConferenceRoomProgram === conferenceRoomProgram) {
      return;
    }
    this.currentDateMoment = this.$moment();
    this.selectedConferenceRoomProgram = conferenceRoomProgram;
    if (
      /* this.selectedProgram && */
      this.selectedConferenceRoomProgram
      && this.selectedConferenceRoomProgram.programs
      && this.selectedConferenceRoomProgram.programs.length
    ) {
      const firstActiveProgram = this.selectedConferenceRoomProgram.programs.find(program => {
        return this.currentDateMoment.isBetween(program.date_start, program.date_end);
      });
      const newSelectedProgramId = firstActiveProgram ? firstActiveProgram.id : this.selectedConferenceRoomProgram.programs[0].id;

      this.$router.push({
        name: 'promo-program-date-program',
        params: {
          date: this.selectedDateParam,
          programId: '' + newSelectedProgramId,
        },
        query: this.$route.query
      });
    }
  }

  public setQuerySearch(): void {
    const querySearch = this.querySearch || '';
    const searchString = this.searchString.trim();
    if (searchString === querySearch) {
      return;
    }

    const date = this.date;
    const queryType = this.queryType;
    if (date) {
      this.$router.push({
        name: 'promo-program-date',
        params: {
          eventId: '' + this.eventId,
          date: date,
        },
        query: {
          type: queryType === 'my' ? 'my' : undefined,
          search: searchString || undefined,
        },
      });
    } else {
      this.$router.push({
        name: 'promo-program',
        params: {
          eventId: '' + this.eventId,
        },
        query: {
          type: queryType === 'my' ? 'my' : undefined,
          search: searchString || undefined,
        },
      });
    }
  }

  public setQueryType(type: 'all' | 'my'): void {
    if (this.queryType === type) {
      return;
    }
    const date = this.date;
    const querySearch = this.querySearch;
    if (date) {
      this.$router.push({
        name: 'promo-program-date',
        params: {
          eventId: '' + this.eventId,
          date: date,
        },
        query: {
          type: type === 'my' ? 'my' : undefined,
          search: querySearch || undefined,
        },
      });
    } else {
      this.$router.push({
        name: 'promo-program',
        params: {
          eventId: '' + this.eventId,
        },
        query: {
          type: type === 'my' ? 'my' : undefined,
          search: querySearch || undefined,
        },
      });
    }
  }

  public onConferenceRoomsMenuArrowLeftClick(): void {
    if (!this.selectedProgramByDate || this.firstVisibleConferenceRoomProgramIndex < 1) {
      return;
    }
    this.firstVisibleConferenceRoomProgramIndex--;
    this.setVisibleConferenceRoomPrograms();
  }

  public onConferenceRoomsMenuArrowRightClick(): void {
    if (
      !this.selectedProgramByDate
      || this.firstVisibleConferenceRoomProgramIndex >= this.selectedProgramByDate.rooms.length - this.maxVisibleConferenceRoomPrograms
    ) {
      return;
    }
    this.firstVisibleConferenceRoomProgramIndex++;
    this.setVisibleConferenceRoomPrograms();
  }

  public onSearchInputKeydownEnter(): void {
    this.setQuerySearch();
  }

  public onSearchButtonClick(): void {
    this.setQuerySearch();
  }

  public onDetailsSizeChange(): void {
    this.scrollToSelectedProgram();
  }

  @Watch('event', { immediate: true })
  private onEventChange(): void {
    this.setProgramByDates();
  }

  @Watch('conferenceRooms', { immediate: true })
  private onConferenceRoomsChange(): void {
    this.setProgramByDates();
  }

  @Watch('date', { immediate: true })
  private onDateChange(): void {
    this.applyNavigation$.next();
  }

  @Watch('conferenceRoomId', { immediate: true })
  private onConferenceRoomIdChange(): void {
    this.applyNavigation$.next();
  }

  @Watch('programId', { immediate: true })
  private onProgramIdChange(): void {
    this.applyNavigation$.next();
  }

  @Watch('queryType')
  private onQueryTypeChange(): void {
    this.setProgramByDates();
  }

  @Watch('querySearch')
  private onQuerySearchChange(): void {
    this.setProgramByDates();
  }

  // @Watch('searchString', { immediate: true })
  // private onSearchStringChange(): void {
  //   this.updateSearch$.next();
  // }

  public get isEventAccessEnabled(): boolean {
    return this.event.personal.is_creator || this.event.is_enabled;
  }

  private applyNavigation(): void {

    // AW-2494
    if (this.event && !this.isEventAccessEnabled) {

      this.$store.dispatch('eventStore/showEventAccessDisabledPopup');

      this.$router.push({
        name: 'event-info',
        params: {
          eventId: this.eventId.toFixed(0)
        }
      });

      return;
    }

    this.selectedProgramByDate = null;
    // this.firstVisibleConferenceRoomProgramIndex = 0;
    // this.selectedConferenceRoomProgram = null;
    this.selectedProgram = null;

    if (
      !this.event || !this.event.date_start || !this.event.date_end
      || !this.programByDates || !this.programByDates.length
    ) {
      // TODO: display something to user
      /* nothing to navigate */
      return;
    }

    const programId = this.programId;

    if (this.date) {
      const foundProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.dateParam === this.date;
      });
      if (foundProgramByDate) {
        this.selectedProgramByDate = foundProgramByDate;
      } else {
        /* navigation parameter is not found, navigate to parent route */
        this.$router.push({
          name: 'promo-program',
          query: this.$route.query
        });
        return;
      }
    } else {
      /* select today or first */
      const todayProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.date.isSame(this.currentDateMoment, 'date');
      });
      const nextDateProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.date.isAfter(this.currentDateMoment, 'date');
      });
      if (todayProgramByDate) {
        this.selectedProgramByDate = todayProgramByDate;
      } else if(nextDateProgramByDate) {
        this.selectedProgramByDate = nextDateProgramByDate;
      } else {
        this.selectedProgramByDate = this.programByDates[0];
      }
    }

    this.selectedDate = this.selectedProgramByDate.date.clone();
    this.selectedDateParam = this.selectedDate.format(DateTimeFormat.DATE_TINY);

    if (programId) {
      let selectedConferenceRoomProgram;
      let selectedProgram;
      let selectedConferenceRoomProgramIndex = -1;
      for (let i = 0; i < this.selectedProgramByDate.rooms.length; i++) {
        for (let j = 0; j < this.selectedProgramByDate.rooms[i].programs.length; j++) {
          if (this.selectedProgramByDate.rooms[i].programs[j].id === programId) {
            selectedConferenceRoomProgramIndex = i;
            selectedConferenceRoomProgram = this.selectedProgramByDate.rooms[i];
            selectedProgram = this.selectedProgramByDate.rooms[i].programs[j];
            break;
          }
        }
        if (selectedProgram) {
          break;
        }
      }

      // this.selectedConferenceRoomProgram = selectedConferenceRoomProgram || null;
      this.selectedConferenceRoomProgram = selectedConferenceRoomProgram || this.selectedConferenceRoomProgram || null;
      this.selectedProgram = selectedProgram || null;

      if (!this.selectedProgram) {
        this.$router.push({
          name: 'promo-program-date',
          params: {
            date: this.selectedDateParam,
          },
          query: this.$route.query
        });
        return;
      }

      if (selectedConferenceRoomProgramIndex > -1) {
        if (selectedConferenceRoomProgramIndex <= this.selectedProgramByDate.rooms.length - this.maxVisibleConferenceRoomPrograms) {
          this.firstVisibleConferenceRoomProgramIndex = selectedConferenceRoomProgramIndex;
        }
      }
    } else if (
      this.selectedProgramByDate
      && this.selectedProgramByDate.rooms
      && this.selectedProgramByDate.rooms.length === 1
    ) {
      const programs = this.selectedProgramByDate.rooms[0].programs;
      // let selectedConferenceRoomProgram;
      let selectedProgram;
      // let selectedConferenceRoomProgramIndex = -1;
      if (this.selectedProgramByDate.date.isSame(this.currentDateMoment, 'date')) {
        // try to find active program
        selectedProgram = programs.find(program => {
          const dateStartMoment = (program && program.date_start) ? this.$moment(program.date_start) : null;
          const dateEndMoment = (program && program.date_end) ? this.$moment(program.date_end) : null;
          if (
            dateStartMoment
            && dateEndMoment
            && this.currentDateMoment.isBetween(dateStartMoment, dateEndMoment)
          ) {
            return true;
          }
          return false;
        });
        if (selectedProgram) {
          // selectedConferenceRoomProgram = this.selectedProgramByDate.rooms[0];
          // selectedConferenceRoomProgramIndex = 0;
        } else {
          selectedProgram = programs[0];
          // selectedConferenceRoomProgram = this.selectedProgramByDate.rooms[0];
          // selectedConferenceRoomProgramIndex = 0;
        }
      } else {
        selectedProgram = programs[0];
      }

      if (selectedProgram) {
        // this.selectedConferenceRoomProgram = selectedConferenceRoomProgram || this.selectedConferenceRoomProgram || null;
        // this.selectedProgram = selectedProgram || null;
        this.$router.push({
          name: 'promo-program-date-program',
          params: {
            date: this.selectedDateParam,
            programId: '' + selectedProgram.id
          },
          query: this.$route.query
        });
        return;
      }

      // if (selectedConferenceRoomProgramIndex > -1) {
      //   if (selectedConferenceRoomProgramIndex <= this.selectedProgramByDate.rooms.length - this.maxVisibleConferenceRoomPrograms) {
      //     this.firstVisibleConferenceRoomProgramIndex = selectedConferenceRoomProgramIndex;
      //   }
      // }
    }

    /*
    this.$router.push({
      name: 'promo-program-date-program',
      params: {
        date: this.selectedDateParam,
        programId: '' + selectedProgram.id
      },
      query: this.$route.query
    });
    */

    this.setVisibleProgramByDates();
    this.setVisibleConferenceRoomPrograms();
    this.scrollToSelectedProgram();
  }

  private scrollToSelectedProgram(): void {
    this.$nextTick(() => {
      this.updateLeftColumnSize();
      if (!this.selectedProgram) {
        return;
      }
      // TODO: user vue refs?
      const containerElement = document.getElementById('programs-list');
      const programElement = document.getElementById(`program-list-item-${this.selectedProgram.id}`);
      if (!containerElement || !programElement) {
        return;
      }
      containerElement.scrollTo({
        top: programElement.offsetTop - 40,
        behavior: 'smooth',
      });
    });
  }

  private updateLeftColumnSize(): void {
    if (!this.selectedProgram) {
      return;
    }

    // TODO: user vue refs?
    const containerElement = document.getElementById('programs-list');
    // const programElement = document.getElementById(`program-list-item-${this.selectedProgram.id}`);
    const contentElement = document.getElementById('program-content');
    if (!containerElement /* || !programElement */ || !containerElement) {
      return;
    }

    // containerElement.style.height = 'calc(100vh - 221px)';
    // const containerHeight = Math.max(document.body.offsetHeight - 220, contentElement.offsetHeight);
    // containerElement.style.height = `${containerHeight}px`;
    containerElement.style.paddingBottom = `${Math.floor(0.5 * contentElement.offsetHeight)}px`;
  }

  private updateLeftColumnPosition(): void {
    if (!this.selectedProgram) {
      return;
    }

    // TODO: user vue refs?
    const containerElement = document.getElementById('programs-list');
    if (!containerElement) {
      return;
    }
    containerElement.style.left = `${(90 - window.scrollX)}px`;
  }

  private setProgramByDates(): void {
    this.programByDates = [];

    if (!this.event || !this.event.date_start || !this.event.date_end) {
      return;
    }

    /* TODO: We're not working with time zones, why dates are in utc timezone? */
    const dateStartMoment = this.$moment(this.event.date_start);
    const dateEndMoment = this.$moment(this.event.date_end);

    if (dateEndMoment.isBefore(dateStartMoment)) {
      return;
    }

    dateStartMoment.hours(0).minutes(0).seconds(1);
    dateEndMoment.hours(23).minutes(59).seconds(59);
    const dateCounterMoment = dateStartMoment.clone().hours(12).seconds(0);

    do {

      const dateMoment = dateCounterMoment.clone();
      const dateProgram: TProgramByDate = {
        date: dateMoment,
        dateText: dateMoment.format(DateTimeFormat.MONTH_DATE_SHORT),
        dateParam: dateMoment.format(DateTimeFormat.DATE_TINY),
        rooms: [],
      };

      if (this.conferenceRooms && this.conferenceRooms.length) {
        this.conferenceRooms.forEach((conferenceRoom: TConferenceRoom): void => {

          if (!conferenceRoom.programs || !conferenceRoom.programs.length) {
            return;
          }

          const conferenceRoomProgram: TConferenceRoomProgram = {
            id: conferenceRoom.id,
            title: conferenceRoom.title,
            programs: [],
          };

          conferenceRoom.programs.forEach((program: TConferenceProgram): void => {
            /* TODO: We're not working with time zones, why dates are in utc timezone? */
            const programDateMoment: Moment = this.$moment(program.date_start);
            programDateMoment.hours(12).minutes(0).seconds(0);

            if (programDateMoment.isSame(dateMoment, 'date')) {
              conferenceRoomProgram.programs.push(program);
            }
          });

          if (conferenceRoomProgram.programs.length > 0) {
            dateProgram.rooms.push(conferenceRoomProgram);
          }
        });
      }

      if (dateProgram.rooms.length > 0) {
        this.programByDates.push(dateProgram);
      }

      dateCounterMoment.add(1, 'day');

    } while (dateCounterMoment.isSameOrBefore(dateEndMoment, 'date'));

    this.applyQuery();
    this.sortProgramByDates();
    this.applyNavigation$.next();
  }

  private applyQuery(): void {
    const queryType = this.queryType;
    const querySearch = this.querySearch;

    if (queryType === 'all' && !querySearch) {
      return;
    }

    let searchString = this.searchString.trim();
    let searchRegExp: RegExp = null;
    if (searchString) {
      searchString = UtilsHelper.escapeRegExp(searchString);
      searchRegExp = new RegExp(`${searchString}`, 'i');
    }

    for (let i = 0; i < this.programByDates.length; i++) {
      if (!this.programByDates[i].rooms || !this.programByDates[i].rooms.length) {
        continue;
      }
      for (let j = 0; j < this.programByDates[i].rooms.length; j++) {
        if (!this.programByDates[i].rooms[j].programs || !this.programByDates[i].rooms[j].programs.length) {
          continue;
        }
        this.programByDates[i].rooms[j].programs = this.programByDates[i].rooms[j].programs.filter(program => {
          if (queryType === 'my' && !program.is_favorite) {
            return false;
          }
          if (searchRegExp && !searchRegExp.exec(program.title)) {
            return false;
          }
          return true;
        });
      }
    }
  }

  private sortProgramByDates(): void {
    if (!this.programByDates || !this.programByDates.length) {
      return;
    }

    for (let i = 0; i < this.programByDates.length; i++) {
      if (!this.programByDates[i].rooms || !this.programByDates[i].rooms.length) {
        continue;
      }
      for (let j = 0; j < this.programByDates[i].rooms.length; j++) {
        if (!this.programByDates[i].rooms[j].programs || !this.programByDates[i].rooms[j].programs.length) {
          continue;
        }

        this.programByDates[i].rooms[j].programs.sort((a, b) => {
          /* AW-1406 - start */
          // const isAActive = this.currentDateMoment.isBetween(a.date_start, a.date_end);
          // const isBActive = this.currentDateMoment.isBetween(b.date_start, b.date_end);
          // if (isAActive && !isBActive) {
          //   return -1;
          // } else if (isBActive && !isAActive) {
          //   return 1;
          // }
          /* AW-1406 - end */

          if (a.date_start === b.date_start) {
            if (a.date_end === b.date_end) {
              return 0;
            } else if (a.date_end > b.date_end) {
              return 1;
            } else {
              return -1;
            }
          } else if (a.date_start > b.date_start) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  }

  private setVisibleProgramByDates(): void {
    // number of visible items is hardcoded to 5 or less

    this.visibleProgramByDates = [];
    if (this.programByDates.length <= 7) {
      this.visibleProgramByDates = [ ...this.programByDates ];
      return;
    }

    const selectedIndex = this.selectedProgramByDateIndex;
    /* edge cases first */
    if (selectedIndex >= this.programByDates.length - 3) {
      this.visibleProgramByDates = this.programByDates.slice(this.programByDates.length - 7);
    } else if (selectedIndex < 3) {
      this.visibleProgramByDates = this.programByDates.slice(0, 7);
    } else {
      this.visibleProgramByDates = this.programByDates.slice(
        selectedIndex - 3,
        selectedIndex + 4,
      );
    }
  }

  private setVisibleConferenceRoomPrograms(): void {
    this.visibleConferenceRoomPrograms = [];
    if (!this.selectedProgramByDate) {
      return;
    }

    const roomPrograms = this.selectedProgramByDate.rooms;
    if (roomPrograms.length <= this.maxVisibleConferenceRoomPrograms) {
      this.visibleConferenceRoomPrograms = [ ...roomPrograms ];
      return;
    }

    const selectedConferenceRoomProgramIndex = this.selectedProgramByDate.rooms.findIndex(p => p === this.selectedConferenceRoomProgram);
    if (selectedConferenceRoomProgramIndex > -1) {
      const lastVisibleIndex = this.firstVisibleConferenceRoomProgramIndex + this.maxVisibleConferenceRoomPrograms - 1;
      if (selectedConferenceRoomProgramIndex > lastVisibleIndex) {
        this.firstVisibleConferenceRoomProgramIndex = selectedConferenceRoomProgramIndex - this.maxVisibleConferenceRoomPrograms;
      } else if (selectedConferenceRoomProgramIndex < this.firstVisibleConferenceRoomProgramIndex) {
        this.firstVisibleConferenceRoomProgramIndex = selectedConferenceRoomProgramIndex + 1;
      }
    }

    if (this.firstVisibleConferenceRoomProgramIndex >= roomPrograms.length - this.maxVisibleConferenceRoomPrograms) {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(roomPrograms.length - this.maxVisibleConferenceRoomPrograms);
    } else if (this.firstVisibleConferenceRoomProgramIndex < 1) {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(0, this.maxVisibleConferenceRoomPrograms);
    } else {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(
        this.firstVisibleConferenceRoomProgramIndex,
        this.firstVisibleConferenceRoomProgramIndex + this.maxVisibleConferenceRoomPrograms,
      );
    }
  }

  private subscribeToPageEvents(): void {
    fromEvent<Event>(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onWindowResize);

    fromEvent<Event>(window, 'scroll')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onWindowScroll);
  }

  private onWindowResize(): void {
    this.updateMaxVisibleConferenceRoomPrograms();
    this.$nextTick(() => {
      this.updateLeftColumnSize();
    });
    this.updateLeftColumnPosition();
  }

  private onWindowScroll(): void {
    this.updateLeftColumnPosition();
  }

  private updateMaxVisibleConferenceRoomPrograms(): void {
    if (!this.$refs.programContent) {
      this.maxVisibleConferenceRoomPrograms = 3;
      return;
    }
    const contentElement = this.$refs.programContent as HTMLDivElement;
    this.maxVisibleConferenceRoomPrograms = Math.max(3, Math.floor((contentElement.offsetWidth - 100) / 400));
  }

  @Watch('maxVisibleConferenceRoomPrograms')
  private onMaxVisibleConferenceRoomPrograms(): void {
    this.setVisibleConferenceRoomPrograms();
  }
}
