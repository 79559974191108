


import Component from 'vue-class-component';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import ChatMessageComposer from '@/_modules/chat/components/chat-message-composer/chat-message-composer.vue';
import {ConferenceProgramChatType} from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import {TChatGroupState} from '@/_modules/chat/types/chat-group-state.type';
import ChatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import eventDiscoveryService, {TEventDiscoveryServiceConfig} from '@/_services/event-discovery.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
  QuestionTypes,
  TQuestionnaire,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionAnswerOption
} from '@/_types/questionnaire.type';
import questionnairesApi from '@/_api/questionnaires.api';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import CookiesHelper from '@/_helpers/cookies-helper';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';

const QUESTIONNAIRE_COOKIE_NAME = 'questionnaire';

@Component({
  components: {
    ChatMessageComposer,
    ChatMessages,
    IconArrowLeft,
    SimplePopup,
  },
  computed: {
    ...mapGetters({
      // connectStatus: 'chatStore/connectStatus',
      // connectError: 'chatStore/lastError',
      // isKickedOut: 'chatStore/isKickedOut',
      getChatGroupStateByGroupId: 'chatStore/getChatGroupStateByGroupId',
    }),
  },
})
export default class ProgramChat extends Vue {

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  @Prop({ type: Object })
  public readonly mainPoll: TQuestionnaire;

  public readonly ConferenceProgramChatType: typeof ConferenceProgramChatType = ConferenceProgramChatType;
  public readonly getChatGroupStateByGroupId: (groupId: string) => TChatGroupState;

  public selectedChatType: ConferenceProgramChatType = null;
  public hasAutoSelectChatTabFired: boolean = false;
  public isPollsContentVisible: boolean = false;
  public isPollDisabled: boolean = false;

  private destroyed$: Subject<void> = new Subject<void>();

  public questionnaires: TQuestionnaire[] = [];
  public isMainPollSending: boolean = false;

  public get isSubmitMainPollButtonDisabled(): boolean {
    return this.isMainPollSending;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  public get isPollsTabVisible(): boolean {
    return (this.mainPoll && this.mainPoll.questions && this.mainPoll.questions.length > 0);
  }

  public get liveChatGroupId(): string {
    return this.program.show_live_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.LIVE) : null;
  }

  public get liveChatGroupState(): TChatGroupState {
    const liveChatGroupId = this.liveChatGroupId;
    return (liveChatGroupId && this.getChatGroupStateByGroupId(liveChatGroupId)) || null;
  }

  public get speakerChatGroupId(): string {
    return this.program.show_speaker_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.SPEAKER) : null;
  }

  public get speakerChatGroupState(): TChatGroupState {
    const speakerChatGroupId = this.speakerChatGroupId;
    return (speakerChatGroupId && this.getChatGroupStateByGroupId(speakerChatGroupId)) || null;
  }

  public get currentChatGroupId(): string {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      return this.liveChatGroupId;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      return this.speakerChatGroupId;
    }
    return null;
  }

  public get currentChatGroupState(): TChatGroupState {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      return this.liveChatGroupState;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      return this.speakerChatGroupState;
    }
    return null;
  }

  public get enterError(): Error {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.enterError) || null;
  }

  public get isEntered(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isEntered) || false;
  }

  public get isEmptyCurrentChat(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return !currentChatGroupState || !currentChatGroupState.messages.length;
  }

  public get isChatMessageComposerDisabled(): boolean {
    return !this.isEntered || !!this.enterError;
  }

  public get messagesError(): Error {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.messagesError) || null;
  }

  public get isMessagesLoading(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isMessagesLoading) || false;
  }

  public get isAllMessagesLoaded(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isAllMessagesLoaded) || false;
  }

  private getPollQuestionClasses(question: TQuestionnaireQuestion): {[key: string]: boolean} {
    const result: {[key: string]: boolean} = {};

    const questionTypeClass: string = 'poll-question-' + question.question_type;
    result[questionTypeClass] = true;

    return result;
  }

  public selectChatType(chatType: ConferenceProgramChatType): void {
    if (chatType === ConferenceProgramChatType.LIVE) {
      if (!this.program.show_live_chat) {
        return;
      }
      this.selectedChatType = ConferenceProgramChatType.LIVE;
    } else if (chatType === ConferenceProgramChatType.SPEAKER) {
      if (!this.program.show_speaker_chat) {
        return;
      }
      this.selectedChatType = ConferenceProgramChatType.SPEAKER;
    }
    this.isPollsContentVisible = false;
  }

  public onPollsTabClick(): void {
    this.selectedChatType = null;
    this.isPollsContentVisible = true;
  }

  public onEnterClick(): void {
    const liveChatGroupId = this.liveChatGroupId;
    const liveChatGroupState = this.liveChatGroupState;
    if (liveChatGroupId && !liveChatGroupState) {
      this.$store.dispatch('chatStore/enterChatGroup', liveChatGroupId);
      this.$store.dispatch('chatStore/requestChatGroupMessagesPage', liveChatGroupId);
      // this.$store.dispatch('chatStore/requestChatGroupContacts', liveChatGroupId);
    }

    const speakerChatGroupId = this.speakerChatGroupId;
    const speakerChatGroupState = this.speakerChatGroupState;
    if (speakerChatGroupId && !speakerChatGroupState) {
      this.$store.dispatch('chatStore/enterChatGroup', speakerChatGroupId);
      this.$store.dispatch('chatStore/requestChatGroupMessagesPage', speakerChatGroupId);
      // this.$store.dispatch('chatStore/requestChatGroupContacts', speakerChatGroupId);
    }
  }

  public onMessagesScrollTopReached(): void {
    const isMessagesLoading = this.isMessagesLoading;
    const isAllMessagesLoaded = this.isAllMessagesLoaded;
    const currentChatGroupId = this.currentChatGroupId;
    if (!currentChatGroupId || isMessagesLoading || isAllMessagesLoaded) {
      return;
    }
    this.$store.dispatch('chatStore/requestChatGroupMessagesPage', currentChatGroupId);
  }

  @Watch('program', { immediate: true })
  private onProgramChange(newVal: TConferenceProgram, oldVal: TConferenceProgram): void {
    const forceAutoSelect: boolean = (newVal && oldVal) && (newVal.id !== oldVal.id);
    this.autoSelectChatTab(forceAutoSelect);
    this.subscribeToServiceEvents();
  }

  @Watch('programId', { immediate: true })
  private onProgramIdChange(): void {
    this.autoSelectChatTab(true);
  }

  @Watch('program.conference_id', { immediate: true })
  private onConferenceIdChange(): void {
    this.autoSelectChatTab(true);
  }

  @Watch('mainPoll.id', { immediate: true })
  private onPollIdChange(): void {
    this.isPollDisabled = this.getPollCookie();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private autoSelectChatTab(force: boolean = false): void {
    if (!force && this.hasAutoSelectChatTabFired) {
      return;
    }
    if (this.program.show_live_chat) {
      this.selectChatType(ConferenceProgramChatType.LIVE);
    } else if (this.program.show_speaker_chat) {
      this.selectChatType(ConferenceProgramChatType.SPEAKER);
    } else {
      this.isPollsContentVisible = true;
    }
    this.hasAutoSelectChatTabFired = true;
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    if (!config) {
      return;
    }
    this.enterChatsAutomatically();
  }

  private enterChatsAutomatically(): void {
    if (!this.selectedChatType) {
      return;
    }
    this.onEnterClick();
  }

  private isStringNotEmpty(input: string): boolean {
    return input.trim() !== '';
  }

  private onOptionAnswerOptionClick(event: Event, answerOption: TQuestionnaireQuestionAnswerOption): void {
    const chosenButton: HTMLButtonElement = event.target as HTMLButtonElement;
    const allAnswerOptions: HTMLButtonElement[] = this.$refs['answer-option_q_' + answerOption.question_id] as HTMLButtonElement[];
    allAnswerOptions.forEach((btn) => {
      btn.classList.remove('chosen');
    });

    chosenButton.blur();
    chosenButton.classList.add('chosen');

  }

  private onMultiAnswerOptionClick(event: Event): void {
    const chosenButton: HTMLButtonElement = event.target as HTMLButtonElement;

    if (chosenButton.classList.contains('chosen')) {
      chosenButton.classList.remove('chosen');
      chosenButton.blur();
    } else {
      chosenButton.classList.add('chosen');
    }

  }

  private getPollCookie(): boolean {
    const questionnaireCookieName = QUESTIONNAIRE_COOKIE_NAME + this.mainPoll.id;
    return CookiesHelper.readCookie(questionnaireCookieName) === `${this.mainPoll.id}`;
  }

  // TODO: refactor
  private async onSubmitMainPollClick(): Promise<void> {
    const allRequests: Promise<any>[] = [];
    const questionnaireId: number = this.mainPoll.id;
    const questionnaireCookieName = QUESTIONNAIRE_COOKIE_NAME + questionnaireId;

    CookiesHelper.createCookie({
      name: questionnaireCookieName,
      value: questionnaireId,
      maxAge: (365 / 2) * 24 * 60 * 60,
    });

    this.isMainPollSending = true;

    this.mainPoll.questions.forEach((q: TQuestionnaireQuestion): void => {
      const qType: QuestionTypes = q.question_type;

      if (qType === QuestionTypes.TEXT) {
        const textareas: HTMLTextAreaElement[] = this.$refs['freeText_q_' + q.id] as HTMLTextAreaElement[];
        const answer = textareas[0].value.trim();
        if (this.isStringNotEmpty(answer)) {
          allRequests.push(questionnairesApi.putAnswer({
            eventId: this.eventId,
            questionnaireId,
            questionId: q.id,
            answerOptionId: -1,
            freeTextAnswer: answer,
          }));
        }

        return;
      }

      if (!q.answer_options || !q.answer_options.length) {
        return; // Remember, this return does not break forEach
      }

      const options: HTMLButtonElement[] = this.$refs['answer-option_q_' + q.id] as HTMLButtonElement[];

      options
        .filter(x => x.classList.contains('chosen'))
        .forEach((button: HTMLButtonElement): void => {
          if (button.classList.contains('chosen')) {
            allRequests.push(questionnairesApi.putAnswer({
              eventId: this.eventId,
              questionnaireId,
              questionId: q.id,
              answerOptionId: parseInt(button.getAttribute('data-id'), 10),
            }));
          }
        });

    });

    await Promise.all(allRequests).catch(() => {
      this.isMainPollSending = false;
    });

    this.isMainPollSending = false;
    this.isPollDisabled = this.getPollCookie();

    this.showPollSentPopup();
  }

  public isPollSentPopupVisible: boolean = false;

  private showPollSentPopup(): void {
    this.isPollSentPopupVisible = true;
  }

  private hidePollSentPopup(): void {
    this.isPollSentPopupVisible = false;
  }
}
